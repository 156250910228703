import Vue from "vue";
import { GridPlugin, Edit, Sort, Toolbar, CommandColumn } from "@syncfusion/ej2-vue-grids";
import { UploaderPlugin } from '@syncfusion/ej2-vue-inputs';
import { ImageEditorPlugin } from "@syncfusion/ej2-vue-image-editor";
import { dateValidationRule } from "@/helpers/formValidation";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import DialogMixin, { DialogResult } from "@/mixins/dialog";
import EmployeeEditTemplate from "@/components/Templates/Employee/EmployeeEditTemplate.vue";
import CalendarTypeEditTemplate from "@/components/Templates/Employee/CalendarTypeEditTemplate.vue";
import { mapGetters } from "vuex";
import { getDropDownValue } from "@/helpers/dropDownHelper";
import CnsCardReader from "@/mixins/cnsCardReader";
Vue.use(GridPlugin);
Vue.use(UploaderPlugin);
Vue.use(ImageEditorPlugin);
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin, CnsCardReader, DialogMixin],
    data() {
        return {
            title: this.$t("employees.create.title"),
            allowServicesGridSave: false,
            servicesGrid: {
                editSettings: {
                    allowEditing: true,
                    allowAdding: true,
                    allowDeleting: true,
                    mode: "Dialog",
                    template: function () {
                        return {
                            template: Vue.component("employee-service-item", EmployeeEditTemplate)
                        };
                    }
                },
                toolbar: ["Add"],
                commands: [
                    /*
                  {
                    type: "Edit",
                    buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                  },
                  */
                    {
                        type: "Delete",
                        buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                    }
                ],
                sortOptions: {
                    columns: [
                        { field: "officeName", direction: "Ascending" },
                        { field: "serviceName", direction: "Ascending" }
                    ]
                },
                validationOptions: {
                    rules: {
                        officeId: {
                            required: true
                        },
                        serviceId: {
                            required: true
                        },
                        hourlyCost: {
                            required: true,
                            number: true
                        }
                    }
                }
            },
            calendarTypesGrid: {
                editSettings: {
                    allowEditing: true,
                    allowAdding: true,
                    allowDeleting: true,
                    mode: "Dialog",
                    template: function () {
                        return {
                            template: Vue.component("employee-calendar-type-item", CalendarTypeEditTemplate)
                        };
                    }
                },
                toolbar: ["Add"],
                commands: [
                    /*
                  {
                    type: "Edit",
                    buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                  },
                  */
                    {
                        type: "Delete",
                        buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                    }
                ],
                sortOptions: {
                    columns: [
                        { field: "officeName", direction: "Ascending" },
                        { field: "calendarTypeName", direction: "Ascending" }
                    ]
                },
                validationOptions: {
                    rules: {
                        officeId: {
                            required: true
                        },
                        calendarTypeId: {
                            required: true
                        },
                        visibility: {
                            required: true
                        }
                    }
                }
            },
            employee: {
                name: "",
                surname: "",
                color: "#0079d6",
                services: [],
                calendarTypes: []
            },
            employeeRoles: [],
            services: [],
            employeeAvatar: null,
            employeeAvatarType: null,
            isEditAvatar: false,
            isFirstEditAvatar: false,
            validationOptions: {
                rules: {
                    name: {
                        required: true,
                        maxLength: 100
                    },
                    surname: {
                        required: true,
                        maxLength: 100
                    },
                    fiscalCode: {
                        maxLength: 16
                    },
                    vatNumber: {
                        maxLength: 11
                    },
                    phoneNumber: {
                        digits: true,
                        maxLength: 20
                    },
                    mobileNumber: {
                        digits: true,
                        maxLength: 20
                    },
                    email: {
                        required: true,
                        email: true,
                        maxLength: 100
                    },
                    employeeRoleId: {
                        required: true
                    },
                    employmentDate: {
                        required: true,
                        date: [dateValidationRule]
                    },
                    terminationDate: {
                        date: [dateValidationRule]
                    },
                    color: {
                        required: true
                    }
                }
            }
        };
    },
    provide: {
        grid: [Sort, Edit, Toolbar, CommandColumn]
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices"
        })
    },
    methods: {
        async create() {
            if (this.employeeAvatar != null) {
                this.employee.imageFileName = `${this.employee.name} ${this.employee.surname}.${this.employeeAvatarType}`;
                const imageSplitted = this.employeeAvatar.split(";base64,");
                this.employee.imageContent = imageSplitted.length == 2 ? imageSplitted[1] : this.employeeAvatar;
            }
            else {
                this.employee.imageFileName = null;
            }
            if (this.employee.contractContent != null && this.employee.contractContent != "") {
                this.employee.contractFileName = `${this.employee.name} ${this.employee.surname}.pdf`;
                const contractSplitted = this.employee.contractContent.split(";base64,");
                this.employee.contractContent = contractSplitted.length == 2 ? contractSplitted[1] : this.employee.contractContent;
            }
            else {
                this.employee.contractFileName = null;
            }
            const createdEmployee = await this.$store.dispatch("employee/create", {
                employee: this.employee
            });
            this.employee.id = createdEmployee.id;
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("employees.create.toastSuccessTitle"), this.$t("employees.create.toastSuccessContent", {
                name: `${this.employee.name} ${this.employee.surname}`
            }));
            //return this.$router.push("/employees");
            return this.$router.push(`/employees/${this.employee.id}/work-weeks/create?setStartDate=false`);
        },
        cancel() {
            return this.$router.push("/employees");
        },
        async servicesGridActionBegin(args) {
            if (args.requestType === "save") {
                // Set office and service names
                args.data["officeName"] = args.form.querySelector("#officeId").value;
                args.data["serviceName"] = args.form.querySelector("#serviceId").value;
                // Allow grid save on positive hourly cost
                if (args.data.hourlyCost >= 0)
                    this.allowServicesGridSave = true;
                // Save grid, if allowed
                if (this.allowServicesGridSave) {
                    this.allowServicesGridSave = false;
                    return;
                }
                // Cancel save
                args.cancel = true;
                // Show confirm dialog
                const result = await this.showConfirmDialog({
                    title: this.$t("employees.negativeHourlyCostValueDialogTitle"),
                    content: this.$t("employees.negativeHourlyCostValueDialogContent", {
                        serviceName: args.data["serviceName"]
                    })
                });
                if (result === DialogResult.Yes) {
                    // Allow service grid save
                    this.allowServicesGridSave = true;
                    // Force grid editor save
                    const grid = this.$refs.employeeServicesGrid;
                    const gridInstance = grid.ej2Instances;
                    gridInstance.endEdit();
                }
                else {
                    // Disallow service grid save
                    this.allowServicesGridSave = false;
                    // Get form validator
                    const formValidator = args.form.ej2_instances[0];
                    // Add and validate rule (to show error message)
                    formValidator.addRules("hourlyCost", { min: 0 });
                    formValidator.validate("hourlyCost");
                    // Remove rule to allow form save
                    formValidator.removeRules("hourlyCost", ["min"]);
                }
            }
        },
        servicesGridActionComplete(args) {
            if (args.requestType === "beginEdit" || args.requestType === "add") {
                // Add Validation Rules
                const employeeServices = this.employee.services;
                args.form.ej2_instances[0].addRules("serviceId", {
                    exists: [
                        function (args) {
                            const officeIdOld = getDropDownValue(args.element.form, "#officeIdOld");
                            const officeId = getDropDownValue(args.element.form, "#officeId");
                            const serviceIdOld = getDropDownValue(args.element.form, "#serviceIdOld");
                            if (args.serviceId == officeIdOld && args.value == serviceIdOld)
                                return true;
                            return !employeeServices.some(service => service.officeId == officeId && service.serviceId == args.value);
                        },
                        this.$t("employees.serviceExists")
                    ]
                });
                if (args.requestType === "add") {
                    args.dialog.width = 380;
                    args.dialog.header = this.$t("employees.newService");
                }
                else {
                    args.dialog.width = 380;
                    args.dialog.header = this.$t("employees.editService", {
                        name: args.rowData["serviceName"]
                    });
                }
            }
        },
        calendarTypesGridActionBegin(args) {
            if (args.requestType === "save") {
                args.data["officeName"] = args.form.querySelector("#officeId").value;
                args.data["calendarTypeName"] = args.form.querySelector("#calendarTypeId").value;
                args.data["visibilityName"] = args.form.querySelector("#visibility").value;
            }
        },
        calendarTypesGridActionComplete(args) {
            if (args.requestType === "beginEdit" || args.requestType === "add") {
                // Add Validation Rules
                const employeeCalendarTypes = this.employee.calendarTypes;
                args.form.ej2_instances[0].addRules("calendarTypeId", {
                    exists: [
                        function (args) {
                            const officeIdOld = getDropDownValue(args.element.form, "#officeIdOld");
                            const officeId = getDropDownValue(args.element.form, "#officeId");
                            const calendarTypeIdOld = getDropDownValue(args.element.form, "#calendarTypeIdOld");
                            if (officeId == officeIdOld && args.value == calendarTypeIdOld)
                                return true;
                            return !employeeCalendarTypes.some(calendarType => calendarType.officeId == officeId && calendarType.calendarTypeId == args.value);
                        },
                        this.$t("employees.calendarTypeExists")
                    ]
                });
                if (args.requestType === "add") {
                    args.dialog.width = 380;
                    args.dialog.header = this.$t("employees.newCalendarType");
                }
                else {
                    args.dialog.width = 380;
                    args.dialog.header = this.$t("employees.editCalendarType", {
                        name: args.rowData["calendarTypeName"]
                    });
                }
            }
        },
        avatarSelected(args) {
            this.showSpinner();
            const reader = new FileReader(), rawImg = reader.readAsDataURL(args.filesData[0].rawFile);
            this.employeeAvatarType = args.filesData[0].type;
            reader.onloadend = () => {
                this.$refs.avatarUploader.clearAll();
                const base64 = reader.result != null ? reader.result.toString() : null;
                if (base64 != null) {
                    this.employeeAvatar = base64;
                    this.editAvatar();
                    this.isFirstEditAvatar = true;
                    this.hideSpinner();
                }
            };
        },
        editAvatar() {
            if (this.employeeAvatar == null)
                return;
            this.showSpinner();
            this.isEditAvatar = true;
            this.$refs.avatarEditor.open(this.employeeAvatar);
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
                document.getElementById("avatarEditor").ej2_instances[0].select("circle");
                this.hideSpinner();
            }, 200);
        },
        removeAvatar() {
            this.employeeAvatar = null;
        },
        replaceAvatar() {
            document.querySelector("#avatarUploaderContainer .e-css.e-btn").click();
        },
        saveEditedAvatar() {
            document.getElementById("avatarEditor").ej2_instances[0].crop();
            const target = document.getElementById('canvasTarget');
            const tctx = target.getContext('2d');
            const syncData = this.$refs.avatarEditor;
            const imgData = new ImageData(syncData.getImageData().data, syncData.getImageData().width, syncData.getImageData().height);
            target.width = syncData.getImageData().width;
            target.height = syncData.getImageData().height;
            tctx.putImageData(imgData, 0, 0);
            this.employeeAvatar = tctx.canvas.toDataURL();
            this.isEditAvatar = false;
            this.isFirstEditAvatar = false;
        },
        cancelEditedAvatar() {
            this.isEditAvatar = false;
            this.$refs.avatarEditor.reset();
        },
        contractSelected(args) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = reader.result != null ? reader.result.toString() : null;
                if (base64 != null)
                    this.employee.contractContent = base64;
            };
            reader.readAsDataURL(args.filesData[0].rawFile);
        },
        setData(data) {
            if (data == null) {
                this.$toast.showWarningToast(this.$t("csnCardReader.toastWarningTitle"), this.$t("csnCardReader.toastWarningContent"));
                return;
            }
            this.employee.name = data.firstName;
            this.employee.surname = data.lastName;
            this.employee.fiscalCode = data.ssn;
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.employeeRoles = await this.$store.dispatch("employeeRole/getAll");
            this.services = await this.$store.dispatch("service/getAll", {
                load: true
            });
            this.employee.services = [];
            this.employee.calendarTypes = [];
            document.getElementById("card-reader").focus();
            this.$on("cnsDataRead", this.setData);
        }
        catch (errors) {
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/employees");
        }
        finally {
            this.hideSpinner();
        }
    },
    beforeDestroy() {
        this.$off("cnsDataRead", this.setData);
    }
});
