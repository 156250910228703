import Vue from "vue";
import AppDropDownListEmployeeCalendarTypeVisibility, { EmployeeCalendarTypeVisibility } from "@/components/Inputs/AppDropDownListEmployeeCalendarTypeVisibility.vue";
import { mapGetters } from "vuex";
export default Vue.extend({
    components: {
        'app-dropdownlist-employee-calendar-type-visibility': AppDropDownListEmployeeCalendarTypeVisibility
    },
    data() {
        return {
            data: {
                officeId: undefined,
                calendarTypeId: undefined,
                visibility: undefined
            },
            offices: [],
            calendarTypes: []
        };
    },
    methods: {
        setOfficeId(value) {
            const selectedCalendarType = this.calendarTypes.find(calendarType => calendarType.id === this.data.calendarTypeId);
            if (selectedCalendarType != undefined && selectedCalendarType.officeId != value)
                this.data.calendarTypeId = undefined;
        }
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices",
            getSingleOfficeId: "account/getSingleOfficeId"
        }),
        isOfficeSelected() {
            return this.data.officeId != undefined;
        },
        officeCalendarTypes() {
            if (!this.isOfficeSelected)
                return [];
            return this.calendarTypes.filter(calendarType => calendarType.officeId == this.data.officeId);
        }
    },
    async created() {
        if (this.data.visibility == undefined)
            this.data.visibility = EmployeeCalendarTypeVisibility.WhenIsAvailable;
        this.$options.calendarTypeId = this.data.calendarTypeId?.toString() ?? "0";
        this.offices = await this.$store.dispatch("office/getAll");
        this.calendarTypes = await this.$store.dispatch("calendarType/getAll", {
            load: false
        });
        this.calendarTypes = this.calendarTypes.filter(calendarType => calendarType.deletedAt == undefined || calendarType.id == this.data.calendarTypeId);
        if (!this.hasMultipleOffices)
            this.data.officeId = this.getSingleOfficeId;
    }
});
