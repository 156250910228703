import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
    data() {
        return {
            data: {
                serviceId: undefined,
                officeId: undefined,
                hourlyCost: undefined
            },
            offices: [],
            services: []
        };
    },
    methods: {
        setOfficeId(value) {
            const selectedService = this.services.find(service => service.id === this.data.serviceId);
            if (selectedService != undefined && !selectedService.offices?.some(serviceOffice => serviceOffice.officeId != value))
                this.data.serviceId = undefined;
        }
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices",
            getSingleOfficeId: "account/getSingleOfficeId"
        }),
        isOfficeSelected() {
            return this.data.officeId != undefined;
        },
        officeServices() {
            if (!this.isOfficeSelected)
                return [];
            return this.services.filter(service => service.offices?.some(serviceOffice => serviceOffice.officeId == this.data.officeId));
        }
    },
    async created() {
        this.$options.officeId = this.data.officeId?.toString() ?? "0";
        this.$options.serviceId = this.data.serviceId?.toString() ?? "0";
        this.offices = await this.$store.dispatch("office/getAll");
        this.services = await this.$store.dispatch("service/getAll", {
            load: false
        });
        this.services = this.services.filter(service => service.deletedAt == undefined || service.id == this.data.serviceId);
        if (!this.hasMultipleOffices)
            this.data.officeId = this.getSingleOfficeId;
    }
});
